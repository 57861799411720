import { Component } from "react";
import { TrashIcon, ArrowRightIcon, CalendarIcon, ClockIcon } from "@heroicons/react/24/outline";
import DeleteSurveyModal from "./Modals/DeleteSurveyModal";
import dayjs from 'dayjs';
import { deleteBot } from "../service/CreateMeeting";
import { getStatusColour } from "../utils";
import { isEmpty } from "../shared";

class MeetingDiv extends Component {

  state = {
    showDeleteModal: false,
    meetingId: this.props.meeting._id,
    isDeleteLoading: false
  }


  handleDeleteMeeting = () => {
    const {handleDeleteBlur} = this.props;
    this.setState({ showDeleteModal: true });
    handleDeleteBlur({showDeleteModal: true});
  };

  handleDeleteModalCloseFn = () => {
    const {handleDeleteBlur} = this.props;
    this.setState({ showDeleteModal: false });
    handleDeleteBlur({showDeleteModal: false});
  };

  handleDeleteModalConfirmation = async () => {
    this.setState({isDeleteLoading: true});
    const { botId,  _id: meetingId, } = this.props.meeting;
    await deleteBot({ botId, meetingId });
    this.setState({showDeleteModal: false, isDeleteLoading: false});
    window.location.reload();
  };


render() {
    const { meeting, handleMeetingClickFn, blurDiv } = this.props
    const {showDeleteModal, isDeleteLoading} = this.state;
    const {
        _id: meetingId,
        meetingTime,
        status,
        botId,
        meetingName
      } = meeting;

      return( 
        <div>
          {showDeleteModal && (<DeleteSurveyModal
            handleDeleteModalCloseFn={this.handleDeleteModalCloseFn}
            handleDeleteModalConfirmation={this.handleDeleteModalConfirmation}
            isDeleteLoading={isDeleteLoading}
          />)}
          <div className={`flex items-center w-full sm:w-[500px] md:w-[800px] lg:w-[900px] xl:w-[900px] 2xl:w-[900px] 3xl:w-[1100px]`}>
            <div
              className={`p-1 sm:px-4 sm:py-6 flex flex-row justify-between gap-3 md:gap-8 w-full border-t border-neutral-400 ${blurDiv ? "blur-sm": ""}`}
              key={meetingId}
            >
              <div className="px-2 py-1 w-full grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-x-48 p-4">
                {/* CHILD 1 */}
                <div className="flex flex-col gap-2">
                  <div className="text-sm tracking-wide font-extrabold flex items-center justify-start">
                    {`${!isEmpty(meetingName) ? `${meetingName}` : "Meeting"}`}
                  </div>
                  <div></div>
                  {/* CHILD 2 */}
                  <div className="flex items-center text-xs text-neutral-500">
                    <div className="flex items-center gap-2">
                      <CalendarIcon className="h-4 w-4 text-neutral-500" />
                      <div className="flex-shrink-0 pr-6">{`${dayjs(meetingTime).format('DD MMM YYYY')}`}</div>
                    </div>
                    <div className="flex items-center gap-2">
                      <ClockIcon className="h-4 w-4 text-neutral-500" />
                      <div>{`${dayjs(meetingTime).format('HH:mm')}`}</div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-end gap-4 sm:gap-0">
                  <div className="flex items-center justify-center">
                    {<div className="text-sm flex items-center justify-center text-black/60 hover:text-black">
                      {status !== "Processed" ? 
                        <span className={`${getStatusColour({status})} px-8 text-sm`}>{status}</span> : 
                        <button className="bg-black bg-opacity-95 text-white rounded-md py-2 px-4 text-sm hover:opacity-80" 
                                onClick={() => handleMeetingClickFn({meetingId, status})}>
                          View Meeting
                        </button>}
                    </div>}
                  </div>
                  {/* Delete Button */}
                  {<div className="sm:pl-6">
                    <TrashIcon className="h-5 w-5 opacity-50 hover:opacity-100 cursor-pointer inline" 
                              onClick={() => this.handleDeleteMeeting({botId})} />
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div> 
      )
  }
}

export default MeetingDiv;