import React, { Component } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import {
  ArrowLeftIcon,
  InformationCircleIcon,
  CalendarIcon,
  ClockIcon 
} from "@heroicons/react/24/outline";
import { MENU_ITEMS, isEmpty } from "../shared";
import { withRouter } from "./withRouterHook";
import { getFollowupEmail, getIntelligence, getMeetingData, saveEditedAnalysis, saveEditedTranscript } from "../service/CreateMeeting";
import dayjs from 'dayjs';
import ReactVideoPlayer from "./ReactVideoPlayer";
import QuillEditor from "./QuillEditor/QuillEditor";
import { generateHTML, getParsedEmail, formatTranscriptForQuill, processIntelligence } from "../utils";
import TemplateDropdown from "./TemplateDropdown";
import LargeLoadingSpinner from "./LargeLoadingSpinner";
import FollowupEmail from "./FollowupEmail";

const TABS = {
  MEETING_NOTES: "Meeting Notes",
  EMAIL: "Email"
};

class MeetingDetails extends Component {
  state = {
    surveyResponse: null,
    isLoading: false,
    uploadFetchingError: false,
    surveyResponseId: null,
    startTime : 0,
    autoPlay: false,
    timestamps: [],
    showError: false,
    meetingName: "",
    showResults: false,
    existingIntelligence: false,
    intelligence: null,
    showAnalysisError: false,
    showAnalysisDivs: false,
    isLoadingAnalysis: false,
    cdmComplete: false,
    isLoadingSaveEdit: false,
    saveStatus: null,
    saveAnalysisStatus: null,
    selectedTab: TABS.MEETING_NOTES,
    followupEmail: null,
    rawIntelligence: null,
    isLoadingEmail: false,
    isEmailError: false,
    intelligenceError: false
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    //Get survey details from DB
    const { match, user } = this.props;
    const { meetingId } = match.params;
    this.setState({ isLoading: true });
    const meetingData = await getMeetingData({
      meetingId,
      username: user?.username
    });
    if (isEmpty(meetingData)) {
      this.setState({ uploadFetchingError: true, isLoading: false });
    } else {
      const {
        transcript,
        videoUrl,
        meetingTime,
        meetingName,
        botId,
        intelligence,
        editedHtmlTranscript,
        editedHtmlAnalysis,
        followupEmail,
       } = meetingData;
       const existingIntelligence = !isEmpty(intelligence) ? true : false;
       const intelligenceError = typeof intelligence === 'object' && intelligence.body === "error";
      
       //If this is the first time getting the original, unedited transcript then we need to convert it to a stringHTML so it renders in the editor.
       //Otherwise if we are fetching an edited transcript, we are already getting it in a HTML string so no processing needs to be done
       const stringTranscript = !isEmpty(editedHtmlTranscript) ? editedHtmlTranscript : formatTranscriptForQuill({transcript});
       const isEmailError = (typeof followupEmail === 'object' && followupEmail.body === "error");

      this.setState({
        isLoading: false,
        transcript: stringTranscript,
        meetingTime,
        videoUrl,
        meetingName,
        botId,
        htmlIntelligence: editedHtmlAnalysis ? editedHtmlAnalysis : generateHTML({intelligence}),
        existingIntelligence,
        cdmComplete: true,
        followupEmail: isEmailError ? null : getParsedEmail({email: followupEmail}),
        isEmailError,
        rawIntelligence: intelligence,
        intelligenceError
      });
    }
  }

  handleGenerateEmail = async () => {
    this.setState({isLoadingEmail: true});
    //Get email
    const { match } = this.props;
    const { meetingId } = match.params;
    const email = await getFollowupEmail({meetingId});
    if(!email || (typeof email === 'object' && email.body === "error")){
      this.setState({isEmailError: true, isLoadingEmail: false });
    }
    else {
      const followupEmail = getParsedEmail({email});
      console.log("Followup email", followupEmail);
      this.setState({isLoadingEmail: false, followupEmail: followupEmail, isEmailError: false});
    }
  }

  handleSaveEditsAnalysis = async ({editedHtml, editedText}) => {
    this.setState({isLoadingSaveEditAnalysis: true});
    const { match } = this.props;
    const { meetingId } = match.params;
    //Save edited HTML to DB (no need to convert to string as we do for the transcript as we only need HTML to render - not sending to Claude for analysis)
    const response = await saveEditedAnalysis({ meetingId, editedHtmlAnalysis: editedHtml, editedTextAnalysis: editedText});
    if (response === "Success") {
      this.setState({isLoadingSaveEditAnalysis: false, saveAnalysisStatus: "success"});
    }
    else {
      this.setState({isLoadingSaveEditAnalysis: false, saveAnalysisStatus: "error"});
    }   
  }

  handleTemplateSelection = async ({selectedOptionValue}) => {
    const {botId} = this.state;
    const { match } = this.props;
    const { meetingId } = match.params;
    this.setState({isLoadingAnalysis: true});
    const intelligence = await getIntelligence({ meetingId, botId, template: selectedOptionValue});
    console.log("Intelligence", intelligence);
    if(isEmpty(intelligence) || intelligence === "error"){
      this.setState({intelligenceError: true, isLoadingAnalysis: false});
    }
    else {
      const htmlIntelligence = generateHTML({intelligence});
      const processedIntelligence =  processIntelligence({intelligence});
      if(isEmpty(processedIntelligence)){
      this.setState({rawIntelligence: intelligence, showAnalysisError: true, isLoadingAnalysis: false, intelligenceError: false})
      }
      else {
        this.setState({rawIntelligence: intelligence, showResults: true, intelligence: processedIntelligence, isLoadingAnalysis: false, htmlIntelligence: htmlIntelligence, intelligenceError: false })
      }
    }
   
  }

  handleMenuItemClick = (menuItem) => {
    const { history } = this.props;
    const { match } = this.props;
    const { surveyId } = match.params;
    if (menuItem === MENU_ITEMS.INSIGHTS) {
      history.push(`/dashboard/recordings/${surveyId}/insights`);
    } else if (menuItem === MENU_ITEMS.VOICES) {
      history.push(`/dashboard/recordings/${surveyId}/voices`);
    } else if (menuItem === MENU_ITEMS.SURVEY) {
      history.push(`/dashboard/recordings/${surveyId}/view-survey`);
    }
  };

  handleBackButtonClick = () => {
    this.props.history.push("/dashboard/meetings");
  };

  onSentimentClick = ({startTime}) => {
    this.setState({startTime: startTime, autoPlay: true})
  }

  onKeywordClick = ({timestamps}) => {
    this.setState({timestamps})
  }

  handleTabClick = ({tab}) => {
    if(tab === TABS.MEETING_NOTES){
      this.setState({selectedTab: tab, isEmailError: false});
    }
    else if (tab === TABS.EMAIL){
      this.setState({selectedTab: tab, showAnalysisError: false});
    }
  }

  render() {
    const {
      transcript,
      videoUrl,
      meetingTime,
      meetingName,
      showError,
      showResults,
      existingIntelligence,
      isLoadingAnalysis,
      showAnalysisError,
      cdmComplete,
      htmlIntelligence,
      isLoadingSaveEditAnalysis,
      isLoading,
      saveAnalysisStatus,
      selectedTab,
      followupEmail,
      isLoadingEmail,
      isEmailError,
      intelligenceError,
      uploadFetchingError
     } = this.state;
     const showAnalysisDivs = existingIntelligence ? existingIntelligence : showResults ? showResults : false;
     const showSummary = showAnalysisDivs && !isEmpty(htmlIntelligence);

   
    return (
      <div>
        <div className="px-4 xl:px-8 max-w-screen-3xl xl:mx-auto h-screen pt-10">
          <div className="pb-12 px-6 sticky top-0 z-10 flex items-start w-fit">
            <ArrowLeftIcon
              className="inline h-6 w-6 text-black mr-4 cursor-pointer hover:scale-110 ml-4 sm:ml-0"
              onClick={this.handleBackButtonClick}
            />
           {!isEmpty(meetingName) && (
             <div className="px-4 sm:px-8 text-lg font-semibold">
               {meetingName && <div className="mb-2 text-base uppercase">{meetingName}</div>}
               {!isEmpty(meetingTime) && <div className="flex gap-x-4 text-xs">
                <div className="flex items-center gap-x-2">
                  <CalendarIcon className="h-4 w-4 text-neutral-600"/>
                  <div className="text-neutral-600">{dayjs(meetingTime).format('DD MMM YYYY')}</div>
                </div>
                <div className="flex items-center gap-x-2">
                  <ClockIcon className="h-4 w-4 text-neutral-600"/>
                  <div className=" text-neutral-600">{dayjs(meetingTime).format('HH:mm')}</div> 
                </div>
               </div>}
             </div>
           )}
          </div>
          {isLoading && <div className="flex pt-24 items-center justify-center"><LargeLoadingSpinner /></div>}
          {showError && <div className="flex items-center justify-center pt-12">Error loading your meeting. Please contact support at amrit@helloxylo.com for assistance.</div>}
          {uploadFetchingError && (
            <div className="flex items-center justify-center pt-12">
              <div className="flex items-center">
                <InformationCircleIcon className="h-5 w-5 mr-2" />
                We're having trouble getting your meeting details. Please try again or contact amrit@helloxylo.com for assistance.
              </div>
            </div>
          )}
          {!showError && !isLoading && !uploadFetchingError && <div className="flex flex-col sm:flex-row">
            <div className="sm:min-w-1/12">
            </div>
            <div className="sm:w-full sm:pb-4">             
              <div className="flex flex-col sm:flex-row">
                  <div className="sm:w-full">
                  <div className="w-full flex mb-8">
                    <div className="w-1/2 mx-6">
                      <ReactVideoPlayer videoUrl={videoUrl} transcript={transcript}/>
                    </div>
                    {<div className="w-1/2 rounded-md px-6 pb-6 text-sm">
                    <div className = "space-x-4 bg-neutral-100 rounded-md px-4 py-2 w-fit">
                      <span onClick={() => this.handleTabClick({tab: TABS.MEETING_NOTES})} className={`px-2 py-1 text-sm font-medium rounded-md  cursor-pointer ${selectedTab === TABS.MEETING_NOTES ? "bg-white text-black" : "text-neutral-500 hover:text-black bg-neutral-100"}`}>Meeting Notes</span>
                      <span  onClick={() => this.handleTabClick({tab: TABS.EMAIL})} className={`px-2 py-1 text-sm font-medium rounded-md  cursor-pointer ${selectedTab === TABS.EMAIL ? "bg-white text-black" : "text-neutral-500 hover:text-black bg-neutral-100"}`}>Follow-Up Email</span>
                    </div>
                    {/* {cdmComplete && !showSummary &&  <div className="uppercase text-amber-700 font-bold text-base">Meeting Notes</div>} */}
                    {selectedTab === TABS.MEETING_NOTES && cdmComplete && intelligenceError && (
                        <div className="py-6 flex flex-col items-center justify-center text-center text-sm">
                          <div className="flex items-center justify-center"> 
                            <InformationCircleIcon className="flex-shrink-0 h-6 w-6 text-red-700 mr-2 inline" />
                            <p className="">
                            Error generating meeting notes. Please retry.
                            </p>
                          </div>
                        </div>
                      )}
                    {selectedTab === TABS.MEETING_NOTES  && cdmComplete && !showAnalysisError && (isEmpty(htmlIntelligence)) ? <TemplateDropdown handleTemplateSelection={this.handleTemplateSelection} isLoadingAnalysis={isLoadingAnalysis} /> : null}
                    {showAnalysisError && (
                      <div className="py-12 flex flex-col items-center justify-center text-center">
                        <p className="text-base font-semibold text-black mb-2">
                          Meeting notes are still being generated
                        </p>
                        <p className="text-sm text-gray-600 mb-4">
                          This process usually takes about 2 minutes. Please check back soon.
                        </p>
                        <button 
                          onClick={() => window.location.reload()} 
                          className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-300"
                        >
                          Refresh Page
                        </button>
                      </div>
                    )}
                     {selectedTab === TABS.MEETING_NOTES && showSummary && (
                      <div>

                      <div className="h-[calc(100vh-theme(spacing.60))]"> 
                        <QuillEditor 
                          value={htmlIntelligence} 
                          onSave={this.handleSaveEditsAnalysis} 
                          isLoadingSaveEdit={isLoadingSaveEditAnalysis} 
                          saveStatus={saveAnalysisStatus} 
                          saveButtonId="saveAnalysisButton"
                        />
                      </div>
                      </div>
                    )}
                    {selectedTab === TABS.EMAIL && 
                      <div>
                        <FollowupEmail 
                          followupEmail={followupEmail} 
                          handleGenerateEmail={this.handleGenerateEmail} 
                          isLoadingEmail={isLoadingEmail} 
                          isEmailError={isEmailError}
                        />
                      </div>
                    }
                    </div>}
                  </div>
                  </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
    );
  }
}

export default withAuthenticator(withRouter(MeetingDetails));
