import axios from "axios";
import { awsExports } from "../aws-exports.js";
import { Amplify } from "aws-amplify";
import { isEmpty } from "../shared";
import { MOCK_MEETING_NOTES, MOCK_MEETING_NOTES_DISCOVERY,  MOCK_MEETING_NOTES_QUARTERLY } from "../utils.js";

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

//API to edit existing survey
export const createMeeting = async ({
  meetingURL,
  joinAt,
  userId,
  meetingTime,
  redactPii,
  meetingName,
  wordBoostArray
}) => {
  if (!meetingURL || !joinAt || !userId || !meetingTime) {
    console.error("No meetingUrl or joinAt or userId provided");
    return null;
  };
  try {
    const payload = {meetingURL, joinAt, userId, meetingTime, redactPii, meetingName, wordBoostArray};
    await axios.post(`${awsExports.MEETINGS}`, {
      ...payload,
    });
    return;
  }
  catch (err) {
    console.log("Error creating meeting", err);
    return null;
  }

};


export const getAllMeetingsForUser = async ({ userId }) => {
  try {
    const response = await axios.get(
      `${awsExports.MEETINGS}?userId=${userId}`
    );
    if (isEmpty(response) || isEmpty(response.data)) return null;
    return response.data; //this returns an array of surveys
  }
  catch(err){
    console.log("Error getting all meetings for userId", userId, err)
    return null;
  }

};

export const deleteBot = async ({botId, meetingId}) => {
  try {
    await axios.delete(
      `${awsExports.MEETINGS}?botId=${botId}&meetingId=${meetingId}`
    );
    console.log("process done")
  }
  catch(err){
    console.log("Error deleting bot", botId)
    return null;
  }
}

export const getMeetingData = async ({meetingId, username}) => {
  try {
     const response = await axios.get(
      `${awsExports.MEETINGS}?meetingId=${meetingId}&userId=${username}`
    );
    if (isEmpty(response) || isEmpty(response.data)) return null;
    return response.data;
  }
  catch(err){
    console.log("Error getting meeting info", meetingId)
    return null;
  }
}

export const getIntelligence = async ({ meetingId, botId, template}) => {
  try {
    // return MOCK_MEETING_NOTES;
    // return MOCK_MEETING_NOTES_DISCOVERY;
    const payload = { meetingId, botId, template};
    const response = await axios.post(`${awsExports.MEETINGS}`, {
      ...payload,
    });
    if (isEmpty(response) || isEmpty(response.data)) return null;
    return response.data;
  }
  catch(err){
    console.log("Error getting meeting info", err)
    return null;
  }
}

export const saveEditedTranscript = async ({editedTextTranscript, meetingId, editedHtmlTranscript}) => {
  try {
    const payload = { updateEditedTranscript: true, editedTextTranscript, editedHtmlTranscript, meetingId};
    const response = await axios.post(`${awsExports.MEETINGS}`, {
      ...payload,
    });
    if (isEmpty(response) || isEmpty(response.data)) return null;
    return response.data;
  }
  catch(err) {
    console.log("error saving transcript => ", err);
    return "";
  }
}

export const saveEditedAnalysis = async ({ meetingId, editedHtmlAnalysis}) => {
  try {
    const payload = { updateEditedAnalysisTranscript: true, editedHtmlAnalysis, meetingId};
    const response = await axios.post(`${awsExports.MEETINGS}`, {
      ...payload,
    });
    if (isEmpty(response) || isEmpty(response.data)) return null;
    return response.data;
  }
  catch(err) {
    console.log("error saving transcript => ", err);
    return "";
  }
}

export const getFollowupEmail = async ({ meetingId}) => {
  try {
    const response = await axios.get(
      `${awsExports.MEETINGS}?meetingId=${meetingId}&getEmail=followup`
    );
    if (isEmpty(response) || isEmpty(response.data)) return null;
   return response.data;
  }
  catch(err){
    console.log("Error getting follow up email - please contact amrit@helloxylo.com for assistance", err)
    return null;
  }
}
